import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/Download.service';
import { NotificationService } from 'src/app/shared/Notification.service';
import { Account } from 'src/app/shared/Account.model';
import { AccountService } from 'src/app/shared/Account.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    public DownloadService: DownloadService,
    public NotificationService: NotificationService,

    public AccountService: AccountService,
  ) {

  }
  ngOnInit() {
    this.BodyListener();
    this.GetByQueryString();
  }
  BodyListener() {
    this.renderer.listen(
      document.body,
      'keyup',
      (event) => {
        if (event) {
          if (event["keyCode"] == 13) {
            this.Login();
          }
        }
      }
    );
  }
  GetByQueryString() {
    this.AccountService.IsShowLoading = true;
    this.AccountService.BaseParameter.ID = environment.InitializationNumber
    this.AccountService.GetByIDAsync().then(
      res => {
        this.AccountService.FormDataLogin = res as Account;
        this.AccountService.FormData = res as Account;
        this.AccountService.IsShowLoading = false;
      },
      err => {
        this.AccountService.IsShowLoading = false;
      }
    );
  }
  Login() {
    this.AccountService.IsShowLoading = true;
    this.AccountService.AuthenticationAsync().then(
      res => {
        this.AccountService.IsShowLoading = false;
        this.AccountService.FormDataLogin = res as Account;
        if (this.AccountService.FormDataLogin) {
          if (this.AccountService.FormDataLogin.Active == true) {
            window.location.href = this.AccountService.FormDataLogin.URL;
          }
          else {
            this.NotificationService.warn(environment.UserNameActive);
          }
        }
        else {
          this.NotificationService.warn(environment.LoginNotSuccess);
          this.AccountService.IsShowLoading = false;
        }
      },
      err => {
        this.NotificationService.warn(environment.LoginNotSuccess);
        this.AccountService.IsShowLoading = false;
      }
    );
  }
}
