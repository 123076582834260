<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            The desktop experience is the best
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            <img width="100" height="100" src="/assets/image/logo.png"
                                title="Authentication"
                                alt="Authentication">
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12">
                            <i class="material-icons prefix pt-2">phone</i>
                            <input type="text" placeholder="{{DownloadService.TitleDienThoai}}" name="TaiKhoan"
                                [(ngModel)]="AccountService.FormData.Account">
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12">
                            <i class="material-icons prefix pt-2">lock_outline</i>
                            <input placeholder="{{DownloadService.TitleMatKhau}}" name="MatKhau"
                                [(ngModel)]="AccountService.FormData.Password" type="password">
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12">
                            <i class="material-icons prefix pt-2">face</i>
                            <input type="text" placeholder="{{DownloadService.TitleHoTen}}" name="Name"
                                [(ngModel)]="AccountService.FormData.Name">
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="input-field col s12">
                            <button (click)="Save()" disabled
                                class="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">{{DownloadService.TitleDangKy}}</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6 m6 l6">
                            <p class="margin medium-small"><a routerLink="/Login" style="font-size: 20px;"
                                    title="{{DownloadService.TitleDangNhap}}">{{DownloadService.TitleDangNhap}}</a>
                            </p>
                        </div>
                        <div class="input-field col s6 m6 l6">
                            <p class="margin right-align medium-small"><a routerLink="/ForgotPassword"
                                    style="font-size: 20px;"
                                    title="{{DownloadService.TitleQuenMatKhau}}">{{DownloadService.TitleQuenMatKhau}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="AccountService.IsShowLoading"></app-loading>