<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            The desktop experience is the best
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            <img width="100" height="100" src="/assets/image/logo.png"
                                title="Authentication"
                                alt="Authentication">
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12">
                            <i class="material-icons prefix pt-2">phone</i>
                            <input type="number" placeholder="Please contact admin"
                                name="Account" [(ngModel)]="AccountService.FormData.Account">
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6 m6 l6">
                            <p class="margin medium-small">
                                <a routerLink="/Login" style="font-size: 20px;"
                                    title="{{DownloadService.TitleDangNhap}}">{{DownloadService.TitleDangNhap}}</a>
                            </p>
                        </div>
                        <div class="input-field col s6 m6 l6">
                            <p class="margin right-align medium-small">
                                <a routerLink="/Register" style="font-size: 20px;"
                                    title="{{DownloadService.TitleDangKy}}">{{DownloadService.TitleDangKy}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="AccountService.IsShowLoading"></app-loading>