import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/shared/Account.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class AccountService extends BaseService {

    List: Account[] | undefined;
    FormData!: Account;
    FormDataLogin!: Account;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "Account";
    }
    GetByIDAsync() {
        let url = this.APIURL + this.Controller + '/GetByIDAsync';
        return this.httpClient.get(url).toPromise();
    }
    AuthenticationAsync() {
        let url = this.APIURL + this.Controller + '/AuthenticationAsync?Account=' + this.FormData.Account + '&Password=' + this.FormData.Password;
        return this.httpClient.get(url).toPromise();
    }
}

