<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">

                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            Hệ thống cần được thực hiện trên máy tính để có được sự trải nghiệm tốt nhất
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            <img width="100" height="100" src="/assets/image/logo.png"
                                title="Cổng xác thực để truy cập hệ sinh thái số"
                                alt="Cổng xác thực để truy cập hệ sinh thái số">
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                           <h5>Đăng ký thành công.</h5>                           
                           <h6>Tài khoản của bạn đã sẵn sàng đăng nhập.</h6>
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="input-field col s6 m6 l6">
                            <p class="margin medium-small">
                                <a routerLink="/Login" style="font-size: 20px;"
                                    title="{{DownloadService.TitleDangNhap}}">{{DownloadService.TitleDangNhap}}</a>
                            </p>
                        </div>
                        <div class="input-field col s6 m6 l6">
                            <p class="margin right-align medium-small">
                                <a routerLink="/Register" style="font-size: 20px;"
                                    title="{{DownloadService.TitleDangKy}}">{{DownloadService.TitleDangKy}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>