export const environment = {
  production: false,  
  APIURL: "https://api.dgkchain.com/api/v1/",    
  APIRootURL: "https://api.dgkchain.com/",        
  InitializationString: "",  
  InitializationNumber: 0,  
  Token: "Token",  
  ThanhVienID: "ThanhVienID",  
  LoginSuccess: "Đăng nhập thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",   
  RegisterSuccess: "Đăng ký thành công.",
  RegisterNotSuccess: "Đăng ký không thành công.",  
  EmailRequired: "Email is required.", 
  EmailExists: "Email đã được đăng ký.", 
  PhoneExists: "Điện thoại đã được đăng ký.", 
  EmailNotExists: "Email chưa đăng ký.", 
  MOMOExists: "MOMO đã được đăng ký.",   
  ForgotPassword: "Mật khẩu mới đã được gửi đến email của bạn.",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",  
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  UserNameActive: "Tài khoản chưa kích hoạt. Vui lòng đợi xác minh từ quản trị.",
};

